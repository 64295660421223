import styles from "./Hero.module.css";
import Goat from "../assets/zilly-7777-header.svg";

const Hero = () => {
  return (
    <div className={styles["hero"]}>
      <img src={Goat} alt="Zilly Goats 7777" className={styles["heroImage"]} />
    </div>
  );
};

export default Hero;
