import styles from "./Footer.module.css";

const Footer = () => {
  return (
    <footer className={styles["footer"]}>
      <div className={styles["footer-icons"]}>
        <a href="https://twitter.com/zillionairegoat" title="Twitter">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="26"
            viewBox="0 0 24 24"
            fill="#fff"
          >
            <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"></path>
          </svg>
        </a>
        <a href="https://discord.gg/zilly" title="Discord">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="26"
            viewBox="0 0 127.14 96.36"
            fill="#fff"
          >
            <path d="M107.7,8.07A105.15,105.15,0,0,0,81.47,0a72.06,72.06,0,0,0-3.36,6.83A97.68,97.68,0,0,0,49,6.83,72.37,72.37,0,0,0,45.64,0,105.89,105.89,0,0,0,19.39,8.09C2.79,32.65-1.71,56.6.54,80.21h0A105.73,105.73,0,0,0,32.71,96.36,77.7,77.7,0,0,0,39.6,85.25a68.42,68.42,0,0,1-10.85-5.18c.91-.66,1.8-1.34,2.66-2a75.57,75.57,0,0,0,64.32,0c.87.71,1.76,1.39,2.66,2a68.68,68.68,0,0,1-10.87,5.19,77,77,0,0,0,6.89,11.1A105.25,105.25,0,0,0,126.6,80.22h0C129.24,52.84,122.09,29.11,107.7,8.07ZM42.45,65.69C36.18,65.69,31,60,31,53s5-12.74,11.43-12.74S54,46,53.89,53,48.84,65.69,42.45,65.69Zm42.24,0C78.41,65.69,73.25,60,73.25,53s5-12.74,11.44-12.74S96.23,46,96.12,53,91.08,65.69,84.69,65.69Z"></path>{" "}
          </svg>
        </a>
        <a href="https://linktr.ee/zillionairegoatclub" title="Website">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 100 100"
            fill="#fff"
          >
            <path d="m42.25 11.75c-10.133 2.0391-18.836 8.0117-24.469 16.25h14.438c0.74219-2.3164 1.6016-4.4922 2.5625-6.4688 2.0117-4.1289 4.5352-7.5664 7.4688-9.7812zm15.5 0c2.9336 2.2148 5.457 5.6523 7.4688 9.7812 0.96094 1.9766 1.8203 4.1523 2.5625 6.4688h14.438c-5.6328-8.2383-14.336-14.211-24.469-16.25zm-9.75 1.5312c-3.4766 0.95312-6.8828 4.3711-9.625 10-0.69922 1.4375-1.3203 3.0391-1.9062 4.7188h11.531zm4 0v14.719h11.531c-0.58594-1.6797-1.207-3.2812-1.9062-4.7188-2.7422-5.6289-6.1484-9.0469-9.625-10zm-36.594 18.719c-2.5156 4.8281-4.0508 10.254-4.3438 16h17.969c0.13672-5.7109 0.86328-11.129 2.0625-16zm19.812 0c-1.2539 4.7734-2.0078 10.227-2.1562 16h14.938v-16zm16.781 0v16h14.938c-0.14844-5.7734-0.90234-11.227-2.1562-16zm16.906 0c1.1992 4.8711 1.9258 10.293 2.0625 16h17.969c-0.29297-5.7461-1.8281-11.172-4.3438-16zm-57.844 20c0.29297 5.7461 1.8281 11.172 4.3438 16h15.688c-1.2031-4.8711-1.9258-10.281-2.0625-16zm22 0c0.14844 5.7773 0.89844 11.23 2.1562 16h12.781v-16zm18.938 0v16h12.781c1.2578-4.7695 2.0078-10.223 2.1562-16zm18.969 0c-0.13672 5.7188-0.85938 11.129-2.0625 16h15.688c2.5156-4.8281 4.0508-10.254 4.3438-16zm-53.188 20c5.6445 8.2539 14.375 14.223 24.531 16.25-2.957-2.2227-5.5078-5.6562-7.5312-9.8125-0.96094-1.9766-1.8203-4.125-2.5625-6.4375zm18.688 0c0.58594 1.6758 1.207 3.25 1.9062 4.6875 2.7422 5.625 6.1484 9.0703 9.625 10.031v-14.719zm15.531 0v14.719c3.4766-0.96094 6.8828-4.4062 9.625-10.031 0.69922-1.4375 1.3203-3.0117 1.9062-4.6875zm15.781 0c-0.74219 2.3125-1.6016 4.4609-2.5625 6.4375-2.0234 4.1562-4.5742 7.5898-7.5312 9.8125 10.156-2.0273 18.887-7.9961 24.531-16.25z" />
          </svg>
        </a>
      </div>
    </footer>
  );
};

export default Footer;
